@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Lexend+Deca&display=swap);


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #0a0c1c;
}

.card {
  width: 90%;
  margin: 80px auto;
  background: #1b1937;
  border-radius: 8px;
  padding-bottom: 32px;
}

.image-container {
  width: 100%;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.overlay {
  width: 110%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #ab5cdb;
  opacity: 0.75;
}

.content-container {
  padding: 0 31.5px;
}

h1 {
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin: 40px 0px 16px;
}

h1 span {
  color: #ab5cdb;
}

.description {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: white;
  opacity: 0.75;
  margin-bottom: 40px;
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stat {
  width: 100%;
}

.stat .stat-count {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29.05px;
  text-align: center;
  color: white;
}

.stat .stat-title {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  opacity: 60%;
}

.stat:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (min-width: 374px) {
  .content-container h1 {
    font-size: 26px;
  }
}

@media screen and (min-width: 767px) {
  .card {
    width: 50%;
  }
  .stats {
    flex-direction: row;
  }
}

@media screen and (min-width: 1023px) {
  html {
    height: 100vh;
    position: relative;
  }

  .card {
    height: 446px;
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .image-container,
  .content-container {
    width: 50%;
  }

  .image-container,
  .image-container img,
  .image-container .overlay {
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  img {
    height: 100%;
    width: auto;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .content-container h1 {
    margin-top: 0;
    font-size: 36px;
    line-height: 44px;
  }

  .content-container h1,
  .content-container .description {
    text-align: left;
  }

  .stats {
    justify-content: space-between !important;
  }

  .stat {
    width: unset;
  }

  .stat:not(:last-child) {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 1180px) {
  .card {
    width: 85%;
  }
}

@media screen and (min-width: 1264px) {
  .card {
    width: 50%;
  }
}

